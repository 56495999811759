'use client';

import { ReactNode } from 'react';

import classNames from 'classnames';

import { Tooltip } from '@uikit';

import InfoCircle from '@uikit/icons/InfoCircle';

export interface BaseInfoTooltipProps {
  title?: string;
  content?: ReactNode;
  iconClassName?: string;
  children?: ReactNode;
  tooltipIconClassName?: string;
  className?: string;
  tooltipClassName?: string;
  icon?: ReactNode;
}

const BaseInfoTooltip = ({
  title,
  content,
  className,
  iconClassName,
  tooltipClassName,
  children,
  tooltipIconClassName,
  icon,
}: BaseInfoTooltipProps) => {
  return (
    <span className={classNames('inline-flex gap-1 items-center', className)}>
      {children}

      <Tooltip
        content={{
          title,
          content,
        }}
        iconClass={tooltipIconClassName}
        tooltipClass={tooltipClassName}
        trigger={
          <span
            className={classNames(
              'icon-[0.875rem] align-middle mx-0.5 cursor-pointer transition-colors text-neutral-500 hover:text-secondary-600',
              iconClassName,
            )}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            {icon || <InfoCircle />}
          </span>
        }
      />
    </span>
  );
};

export default BaseInfoTooltip;
