'use client';
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import { useIsClient } from 'usehooks-ts';

export const WIDGET_CONTAINER_ID = 'widget-container';

export const WidgetContainerProvider = ({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) => {
  return (
    <>
      {children}

      <div
        className={classNames(
          'fixed bottom-0 right-0 z-10 pr-[var(--removed-body-scroll-bar-size)]',
          className,
        )}
        {...props}
      >
        <div id={WIDGET_CONTAINER_ID} className="flex items-end p-4 gap-4 flex-col" />
      </div>
    </>
  );
};

export const WidgetPortal = ({ children }: { children: ReactNode }) => {
  const isClient = useIsClient();
  const container =
    typeof document !== 'undefined' ? document.getElementById(WIDGET_CONTAINER_ID) : null;

  // NOTE: without isClient portal will not render for the first time
  if (!isClient || !container) {
    return;
  }

  return createPortal(children, container);
};
