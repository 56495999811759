'use client';

import React, { ReactNode } from 'react';

import { captchaContainerName } from '@shared/helpers/captcha';

interface TurnstileProviderProps {
  children?: ReactNode;
}

const TurnstileProvider = ({ children }: TurnstileProviderProps) => {
  return (
    <>
      <div id={captchaContainerName} className="hidden" />
      {children}
      <script src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit" />
    </>
  );
};

export default TurnstileProvider;
